.global-admin div#wrapper {
  background: #141f2b;
  min-height: 91.5vh;
}

body.modal-open-scroll {
  overflow: hidden;
}

/* 
.global-admin .page-content.common-page-content {
  background: #232d38;
  min-height: calc(100vh - 168px);
} */
.global-admin .page-content.common-page-content {
  background: #232d38;
  min-height: calc(100vh - 116px);
  height: auto;
  overflow: hidden;
}

.global-admin ul.sidebar a.nav-link span {
  color: #eff3f7;
}

.global-admin h3.page-title {
  color: #fff;
}

.global-admin nav.site-details-tab .nav-tabs.nav-tabs a.nav-item {
  padding: 8px 10px;
  border-bottom: 1px solid #576473;
  color: #1dd4b7;
}

.global-admin nav.site-details-tab .nav-tabs.nav-tabs a.nav-item.active {
  background: transparent;
  border-bottom: 2px solid #1dd4b7;
}

.global-admin .vehicle-form h3 {
  color: #ffffff;
}

.global-admin .booking-list-form {
  padding: 0;
  border: none;
}

.global-admin .tab-content.site-detailtab-cont {
  margin: 24px 0 0px;
}

.global-admin button.btn.theme-btn.add-booking {
  border-color: #0b907b;
  margin-bottom: 34px;
  opacity: 1;
  background: #0b907b;
  margin-left: 0;
  margin-right: 0;
}

.global-admin button.btn.theme-btn.add-booking:hover {
  color: #fff;
}

.global-admin .booking-list-form .form-group .form-control {
  background: #576473;
  color: #eff3f7;
}

.global-admin .form-group .form-control {
  background: #eff3f7;
  color: #3f4b57;
}

.global-admin button.btn.white-btn {
  background: #232d38;
  border: 2px solid #1dd4b7;
  color: #1dd4b7;
}

.global-admin button.btn.white-btn.dl-btn {
  background: #232d38;
  border: 2px solid #576473;
  border-radius: 5px;
}

.global-admin a.back-link {
  display: flex;
  align-items: center;
}

.global-admin a.back-link img {
  width: 9px;
  height: auto;
}

.global-admin a.back-link + h3.page-title {
  margin-right: auto;
  margin-left: 18px;
}

.global-admin table.dataTable tbody th:first-child,
table.dataTable tbody td:first-child {
  padding-left: 12px !important;
}

.global-admin .table-responsive.main-street-table {
  margin: 0 -32px;
  width: calc(100% + 64px);
}

.global-admin .table-responsive.main-street-table {
  margin: 22px -32px 0;
  width: calc(100% + 64px);
}

.global-admin table.dataTable thead th {
  background: #3f4b57;
  box-shadow: inset 0px -1px 0px #576473, inset 0px 1px 0px #576473;
  color: #eff3f7;
}

.global-admin table.dataTable.display > tbody > tr > td {
  color: #fff;
  background: #232d38;
  box-shadow: none !important;
  border-bottom: 1px solid #141f2b;
}

.global-admin .form-group label {
  color: #fff;
}

.global-admin .file-input {
  background: #fff;
}

.project-info-row {
  margin-top: 48px;
}

.global-admin .contact-item-header p {
  color: #fff;
}

.global-admin .contact-item:not(:last-child) {
  border-bottom: 2px solid #3f4b57;
}

.global-admin .divider-form {
  height: 2px;
  background: #3f4b57;
}

.global-admin .vehicle-form {
  border: 1px solid #3f4b57;
}

.global-admin div#add-contact-form {
  margin-top: 16px;
  margin-bottom: 16px;
}

.global-admin
  .page-content.common-page-content.projects-page-cont
  .table-responsive.main-street-table {
  margin: 24px -24px 0;
  width: calc(100% + 48px);
}

.global-admin .page-content.common-page-content.projects-page-cont {
  padding: 24px;
}

.global-admin .pro-title-with-detail h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "Inter";
  margin-bottom: 12px;
  white-space: nowrap;
}

.pro-detail-tag {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.pro-detail-tag span.text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #dbe2e9;
  font-family: "Inter";
}

.pro-detail-tag span.dot {
  width: 4px;
  height: 4px;
  background: #dbe2e9;
  display: inline-block;
  border-radius: 50%;
  margin: 0 6px;
}

.pro-title-with-detail {
  margin-right: 25px;
}

.filter-wrapper.projects-filter-wrappers
  .form-group.form-inline.filter-search
  input.form-control {
  width: 100%;
  background: #141f2b;
  border-radius: 6px;
  color: #dbe2e9;
  height: 56px;
}

.filter-wrapper.projects-filter-wrappers
  .form-group.form-inline.filter-search
  input.form-control::placeholder {
  color: #dbe2e9;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.filter-wrapper.projects-filter-wrappers .form-group.form-inline.filter-search {
  width: calc(100% - 119px);
}

.filter-wrapper.projects-filter-wrappers .f-left {
  width: 100%;
}

.global-admin .filter-wrapper.projects-filter-wrappers button.btn.white-btn {
  border-width: 1px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}

.global-admin
  .filter-wrapper.projects-filter-wrappers
  button.btn.white-btn.filter-btn
  img {
  margin-left: 8px;
  width: 12px;
}

.global-admin
  .filter-wrapper.projects-filter-wrappers
  button.btn.white-btn.filter-btn
  img {
  margin-left: 8px;
  width: 12px;
  height: 13px;
}

/* .global-admin
  .page-content.common-page-content.projects-page-cont
  .table-responsive.main-street-table {
  margin: 12px -24px 0;
  width: calc(100% + 48px);
  border-top: none;
  padding-top: 20px;
  overflow: hidden;
} */
.global-admin
  .page-content.common-page-content.projects-page-cont
  .table-responsive.main-street-table {
  margin: 12px -24px 0;
  width: calc(100% + 48px);
  border-top: none;
  height: calc(100vh - 320px);
  overflow-y: auto;
}

.global-admin
  .page-content.common-page-content.projects-page-cont
  .table-responsive.main-street-table
  thead {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  top: 0;
}

.global-admin
  .page-content.common-page-content.projects-page-cont
  .table-responsive.main-street-table
  .dataTables_wrapper
  .dataTables_length {
  float: right;
  margin-bottom: 5px;
}

td .custom-projectlistlink {
  color: #1dd4b7 !important;
  margin-left: 14px !important;
  text-decoration: underline;
}

.global-admin a.prodet-back-link {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #dbe2e9;
}

.global-admin a.prodet-back-link img {
  width: 15px;
  margin-right: 4.5px;
}

.global-admin .pro-title-with-detail h4 span.tag {
  padding: 3px 8px;
  line-height: 14px;
  font-weight: 700;
  margin-left: 8px;
}

.global-admin a.prodet-back-link:hover {
  color: #1dd4b7;
  text-decoration: none;
}

.global-admin .date-tag-cust span.date-ctitle {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #dbe2e9;
  margin-right: 6px;
}

.global-admin .date-tag-cust {
  white-space: nowrap;
}

.global-admin span.date-cval {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.global-admin .projectdetails-tabbing {
  margin-top: 24px;
}

.global-admin .projectdetails-tabbing nav.site-details-tab {
  margin: 0 -24px;
  width: calc(100% + 48px);
  max-width: inherit;
}

.global-admin
  .projectdetails-tabbing
  nav.site-details-tab
  .nav-tabs.nav-tabs
  a.nav-item {
  width: 200px;
  max-width: 20%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #a0aebd;
  padding: 15px 15px 13px;
  border-bottom: 2px solid #141f2b;
}

.global-admin .projectdetails-tabbing nav.site-details-tab .nav-tabs.nav-tabs {
  border-bottom: none;
  background: #141f2b;
}

.global-admin
  .projectdetails-tabbing
  nav.site-details-tab
  .nav-tabs.nav-tabs
  a.nav-item.active {
  color: #fff;
  font-weight: 700;
  border-bottom: 2px solid #1dd4b7;
}

.prd-item p.prd-title {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #dbe2e9;
}

.prd-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 0px;
}

.prd-item p.prd-val {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #fcfcfc;
  margin-bottom: 0;
}

.global-admin .vehicle-form.glob-form h3 {
  padding: 0 16px 16px;
  margin: 0 -16px 0px;
  border-bottom: 1px solid #3f4b57;
}

.global-admin .vehicle-form.glob-form .form-save-btn {
  margin-top: 22px;
}

.vehicle-form.glob-form table.table.prd-data-table {
  margin: 0;
}

.vehicle-form.glob-form .table-responsive {
  margin: -1px -16px -16px;
  width: calc(100% + 32px);
}

.vehicle-form.glob-form table.table.prd-data-table td {
  border-top: 1px solid #3f4b57;
  padding: 16px 24px;
}

.cd-action a img {
  width: 16px;
}

.cd-action a.ad-edit {
  margin-right: 15px;
}

.global-admin .file-input.upload-databox {
  background: #232d38;
  border: 1px dashed #3f4b57;
  border-radius: 6px;
  padding: 16px 20px;
  justify-content: space-between;
}

.file-input.upload-databox .file-input-text {
  display: flex;
  align-items: center;
  opacity: 1;
}

.file-input.upload-databox .file-input-text p {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eff3f7;
  margin-bottom: 0;
  word-break: break-all;
}

.file-input.upload-databox .file-input-text p span {
  color: #1dd4b7;
  margin-left: 5px;
  text-decoration: underline;
}

.file-input.upload-databox .file-input-text img {
  width: 16px;
  margin-right: 12px;
}

.global-admin .file-input.upload-databox.added-data-box .file-input-text img {
  width: 23px;
  height: auto;
}

.global-admin .file-input.upload-databox.added-data-box .file-input-text {
  margin-right: 40px;
}

span.file-size {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eff3f7;
}

/* a.delete-file {
  margin-left: auto;
  display: flex;
  align-items: center;
} */

a.delete-file img {
  width: 16px;
}

.filter-data-box.glob-sec-filter {
  margin: 24px 0 0;
  width: calc(100% + 48px);
  /* border-top: 1px solid #141f2b;
  padding: 20px 24px 0; */
}

.filter-data-box.glob-sec-filter p.f-text {
  color: #fcfcfc;
}

.filter-data-box.glob-sec-filter .selected-filter .sf {
  background: #dbe2e9;
  color: #3f4b57;
}

.bookig-type-box h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  margin-bottom: 11px;
}

.booking-option-box.vehicle-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.left-book-option h5 {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #fcfcfc;
  margin-bottom: 9px;
}

.global-admin .switch input:checked + .slider {
  background-color: #1dd4b7;
}

.left-book-option p {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #dbe2e9;
  margin-bottom: 0;
}

.bookig-type-box {
  padding-bottom: 12px;
  border-bottom: 1px solid #3f4b57;
  margin-bottom: 24px;
}

.cust-projset-box h4 {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 12px;
}

.global-admin .cust-projset-box .form-group label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.cust-projset-box .left-book-option h5 {
  margin-bottom: 12px;
}

.cust-projset-box {
  padding-bottom: 4px;
}

button.theme-btn:focus {
  outline: none;
}

button.theme-btn {
  cursor: pointer;
}

.add-new-field a {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0b907b;
}

.add-new-field a img {
  width: 17px;
  margin-left: 5px;
}

.add-new-field {
  margin-top: 10px;
}

.add-new-field a:hover {
  text-decoration: none;
  color: #0b907b;
}

ul.cust-option-list {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
  overflow: auto;
  max-height: 132px;
}

ul.cust-option-list li.co-liitem {
  display: flex;
  width: 100%;
  padding: 10px 0;
  align-items: center;
}

ul.cust-option-list li.co-liitem img.drag-img {
  width: 24px;
  margin-right: 8px;
  height: auto;
}

ul.cust-option-list li.co-liitem h5 {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3f4b57;
  margin-bottom: 0;
}

ul.cust-option-list li.co-liitem a.option-del {
  margin-left: auto;
  display: flex;
}

ul.cust-option-list li.co-liitem a.option-del img {
  width: auto;
  height: auto;
  margin-right: 8px;
}

.option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.option-header h6 {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3f4b57;
  margin-bottom: 0;
}

.option-header a img {
  width: 24px;
  height: auto;
}

.modal-content.cust-new-proj-modal .modal-body {
  padding: 0;
}

.modal-content.cust-new-proj-modal {
  background: #3f4b57;
  box-shadow: 0px 8px 12px rgb(20 31 43 / 4%), 0px 2px 6px rgb(20 31 43 / 8%);
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
}

.modal-content.cust-new-proj-modal .modal-body {
  padding: 0;
}

.custom-option-box {
  background: #eff3f7;
  border-radius: 6px;
  padding: 20px;
  overflow: auto;
  max-height: 280px;
  position: relative;
}

.custmodal-formbox {
  padding: 20px 30px 20px 30px;
  border-bottom: 1px solid rgb(239 243 247 / 10%);
}

.custmodal-formbox.vehicle-types-section {
  padding: 20px 0 8px;
  border-bottom: 0;
}

.modal-content.cust-new-proj-modal .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  border-bottom: 1px solid rgb(239 243 247 / 10%);
}

.modal-dialog.cust-new-modal {
  width: 500px;
  transform: none !important;
  margin: 1.75rem auto;
  top: auto;
}

.modal-content.cust-new-proj-modal .modal-header h4.modal-title {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 0;
}

.modal-content.cust-new-proj-modal .modal-header button.close-project {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  padding: 0;
  margin: 0;
  opacity: 1;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.modal-content.cust-new-proj-modal .modal-header button.close-project:focus {
  outline: 0;
  outline: 0;
}

.modal-content.cust-new-proj-modal .modal-header button.close-project span {
  display: block;
  width: 20px;
  height: 20px;
  line-height: 16px;
  text-align: center;
}

.modal-content.cust-new-proj-modal .modal-header button.close-project img {
  margin-left: 4px;
}

.modal-open .modal.custom-modal {
  overflow-y: auto;
}

.custmodal-formbox {
  padding: 20px 32px 8px;
  border-bottom: 1px solid rgb(239 243 247 / 10%);
}

.global-admin .custmodal-formbox .form-group label {
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
}

.option-box-wrapper {
  padding: 20px 32px;
}

.modal-cs-btnbox {
  padding: 12px 32px 32px;
}

.modal-cs-btnbox button.theme-btn {
  background: #0b907b;
  border-radius: 5px;
  padding: 12px 37px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 auto auto;
}

.modal-cs-btnbox button.theme-btn:hover,
button.theme-btn:hover {
  color: #fff;
}

.global-admin .modal-backdrop.fade.show {
  background: rgb(159 171 183 / 49%);
  backdrop-filter: blur(12px);
  opacity: 1;
  display: block;
}

.modal-content.cust-new-proj-modal .modal-header button.close:focus {
  outline: none;
}

table.table.field-table tr th,
table.table.field-table tr td {
  padding: 6px;
  border: none;
  vertical-align: middle;
}

p.cuts-table-val {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  margin-bottom: 0;
}

table.table.field-table tr th p.th-title {
  margin-bottom: 0;
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: #ffffff;
}

table.table.field-table tr td a.ad-edit img {
  width: 16px;
}

.action-div {
  width: 80px;
  text-align: center;
}

.action-div.acbox {
  width: 100px;
  text-align: center;
}

table.table.field-table {
  margin-bottom: 0;
}

table.table.field-table tr td {
  padding: 10px 6px;
}

table.table.field-table tr td .switch input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

table.table.field-table tr td .switch .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 2px;
}

table.table.field-table tr td .switch {
  width: 30px;
  height: 16px;
}

.table-drag-img {
  width: 24px;
  height: auto;
}

.table-cust-btn {
  margin-top: 20px;
  padding: 0 8px 4px;
}

.global-admin .table-cust-btn button.btn.white-btn {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #1dd4b7;
  border: 1px solid #1dd4b7;
  border-radius: 4px;
  padding: 4px 12px;
  height: 32px;
  text-transform: capitalize;
}

.del-modal-cont-wrapper {
  padding: 32px;
  text-align: center;
}

.del-img-box {
  background: #141f2b;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  margin: 32px auto 24px;
  align-items: center;
  justify-content: center;
}

.del-img-box img {
  width: 28px;
  height: auto;
}

p.conformation-text {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0 30px;
}

p.del-info {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #fcfcfc;
  margin-bottom: 0;
}

.delmodal-cs-btnbox {
  margin: 32px 0 15px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.delmodal-cs-btnbox button.theme-btn {
  width: calc(50% - 8px);
  height: 48px;
  font-weight: 700;
}

.delmodal-cs-btnbox button.theme-btn.btn-default {
  border-color: #576473 !important;
  background: #576473 !important;
  border-radius: 5px !important;
}

.filter-data-box.glob-sec-filter.sh-result-block {
  margin: 0px 0px 0px -24px !important;
  padding: 0;
}

.sh-result p.f-text.entiries {
  align-items: center;
}

.sh-result p.f-text.entiries {
  align-items: center;
}

/* .custom-option-box input {
  margin-right: 10px;
} */
img.cross-btn {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  margin-right: 8px;
}

.cross-btn:hover {
  cursor: pointer;
}

#filterModal button.close_filter {
  display: flex;
  align-items: center;
}

#filterModal button.close_filter:focus {
  outline: 0;
}

#filterModal button.close_filter img {
  margin-left: 4px;
}

button.close-project {
  display: flex;
  align-items: center;
  color: #ffff;
}

.custom-op-input {
  position: relative;
  margin-top: 10px;
}

.modal.show {
  display: block !important;
}

.filtercheck-item.vehicle-item span.fci-title {
  color: #fff;
  width: unset;
}

ul.nav.nav-tabs.filter-tabs a.nav-link:hover span {
  color: #ffff;
}

/* .btm-pagination {
  position: absolute;
  transform: translate(50%, 50%);
  right: 4%;
  bottom: -82%;
  height: 100%;
  align-items: end;
} */
/* .btm-pagination {
  position: fixed;
  right: 4%;
  bottom: 2%;
  align-items: end;
} */

.filtercheck-item span.badge {
  color: #085e50 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../images/date-icon.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  margin-right: 0 !important;
  filter: invert(1) !important;
}

.edit-text a.edit-icon {
  flex-wrap: wrap;
  gap: 0 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #0b907b !important;
  width: fit-content;
}

/* calender css */
.date-box {
  background: #141f2b;
  border-radius: 6px;
}

.date-box .MuiInputBase-root.MuiOutlinedInput-root {
  flex-flow: row-reverse !important;
  padding-right: 0;
  border-radius: 6px;
  padding: 4px 0;
}

.date-box fieldset {
  border-color: transparent !important;
}

.date-box button {
  filter: invert(1);
}

.date-box button:hover,
.date-box button:focus {
  background-color: transparent;
  outline: unset;
}

/* .MuiPickersArrowSwitcher-root.css-9reuh9-MuiPickersArrowSwitcher-root button,
.MuiPickersArrowSwitcher-root.css-9reuh9-MuiPickersArrowSwitcher-root
  button:focus {
  color: #0b907b;
  outline: unset;
}

.css-195y93z-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border: 1px solid #0b907b !important;
  background: #0b907b;
  border-radius: 20px;
  color: #fff;
}

.MuiPickersDay-today {
  color: #fff !important;
  background: #0b907b !important;
  border-radius: 20px !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  font-weight: 500;
  background: rgba(29, 212, 183, 0.46) !important;
  border-radius: 21px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: unset !important;
}

.css-195y93z-MuiButtonBase-root-MuiPickersDay-root:focus,
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root:focus {
  outline: 0;
}

.css-10fao8b-MuiPickersCalendarHeader-labelContainer {
  max-height: fit-content !important;
  width: 100%;
  min-width: max-content;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}

.css-9reuh9-MuiPickersArrowSwitcher-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: unset;
  z-index: 1;
  transform: translate(-50%, -50%);
  bottom: 0;
  left: 50%;
  justify-content: space-between;
}

.css-1vcokmn-MuiCalendarOrClockPicker-root {
  padding-bottom: 40px;
  border: 2px solid #dbe2e9;
  border-radius: 6px;
}

.css-xb7uwb-MuiPickersArrowSwitcher-spacer {
  width: 76px !important;
}

.css-15fehp8-MuiTypography-root-PrivatePickersMonth-root.Mui-selected,
.css-15fehp8-MuiTypography-root-PrivatePickersMonth-root.Mui-selected,
.css-3eghsz-PrivatePickersYear-button.Mui-selected,
.css-3eghsz-PrivatePickersYear-button.Mui-selected:focus {
  color: #fff;
  background-color: #0b907b !important;
  outline: unset;
}

.css-1sy1a1q-MuiTypography-root-PrivatePickersMonth-root:focus,
.css-1sy1a1q-MuiTypography-root-PrivatePickersMonth-root:hover,
.css-3eghsz-PrivatePickersYear-button:focus,
.css-3eghsz-PrivatePickersYear-button:hover {
  background-color: rgba(0, 0, 0, 0.04);
  outline: 1px solid #0b907b;
} */
tbody.custom-table-div-scroll {
  /* max-height: 264px; */
  display: block;
  /* overflow-y: auto; */
  width: 100%;
  max-width: max-content;
}

.field-table tr {
  display: table;
  width: 100%;
}

.custom-table-div-scroll p.cuts-table-val {
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
  max-width: fit-content;
}

.custom-filter-result-block-display .edit-text {
  margin: 24px 0 0;
}
/* calender css */

button:focus {
  outline: unset !important;
}

.MuiPickersDay-today {
  color: #fff !important;
  background: #0b907b !important;
}
.MuiCalendarOrClockPicker-root {
  padding-bottom: 40px;
  border: 2px solid #dbe2e9;
  border-radius: 6px;
}

.MuiPickersCalendarHeader-labelContainer {
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  width: 100%;
  min-width: max-content;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}

.MuiPickersArrowSwitcher-root {
  display: flex;
  position: absolute;
  top: unset;
  z-index: 1;
  transform: translate(-50%, -50%);
  bottom: 0;
  left: 50%;
  justify-content: space-between;
}

button.MuiButtonBase-root.MuiButton-root {
  color: #0b907b;
  outline: unset;
}

.MuiPickersArrowSwitcher-spacer {
  width: 76px !important;
}

.MuiPickersDay-today:not(.Mui-selected) {
  border: 1px solid #0b907b !important;
}

button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  font-weight: 500;
  background: rgba(29, 212, 183, 0.46) !important;
  border-radius: 21px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #000;
}

button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:focus {
  outline: 0;
}

button.PrivatePickersMonth-root.Mui-selected {
  color: #fff;
  background-color: #0b907b !important;
  outline: unset;
}

button.PrivatePickersYear-yearButton.Mui-selected {
  color: #fff;
  background-color: #0b907b !important;
  outline: unset;
}

.row.add-new-org-form {
  padding: 24px 32px;
}

/* .fixed-scrollbar {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
  padding: 0 15px;
} */
.fixed-scrollbar {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  padding: 0 15px;
}

.org-table {
  margin: 0 -24px;
}

.org-table .table-responsive.projectuser thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  width: 100%;
  z-index: 1;
}

.table-responsive.projectuser {
  overflow-x: unset !important;
  height: calc(100vh - 354px);
  overflow-y: auto;
}

.org-table .pagination-div {
  padding-right: 24px;
}

.activity-scrollbar {
  height: calc(100vh - 390px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 -24px;
}

@media (max-width: 991px) {
  .activity-scrollbar {
    height: calc(100vh - 450px);
    overflow-y: auto;
    overflow-x: auto;
    margin: 0 -24px;
  }
}

.table-responsive.organization-user {
  overflow-x: unset !important;
  height: calc(100vh - 376px);
  overflow-y: auto;
}

/* add new div css */

.page-content.common-page-content.projects-page-cont.common-fix-div {
  height: calc(100vh - 109px);
  min-height: calc(100vh - 92px);
  border-radius: 6px 0 0;
}
.global-admin
  .page-content.common-page-content.projects-page-cont
  .table-responsive.main-street-table.custom-list-div-scroll {
  height: calc(100vh - 250px);
}
.selection-custom-div .form-control-language__control {
  height: 38px;
}

.modal.show.fixed-small-scroll {
  overflow-y: auto;
}
body {
  overflow: hidden;
}

.preset-form-button-group {
  display: flex;
  justify-content: start;
  gap: 8px;
}
