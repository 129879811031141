.body{
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: rgba(85, 85, 105, 0.5);
  z-index: 99999;
  transition: opacity 0.5s, clip-path 1s;
 
  
}
.main{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  

}


.b {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.b div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #1b695d;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.b div:nth-child(1) {
  left: 8px;
  animation: b1 0.6s infinite;
}
.b div:nth-child(2) {
  left: 8px;
  animation: b2 0.6s infinite;
}
.b div:nth-child(3) {
  left: 32px;
  animation: b2 0.6s infinite;
}
.b div:nth-child(4) {
  left: 56px;
  animation: b3 0.6s infinite;
}
@keyframes b1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes b3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes b2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}









 
 
 
  
